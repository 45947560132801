.foodGroups{
   height: 93vh;
   padding-left: 20px;
   padding-right: 20px;
   color: white;
   border:solid 2vh white;
   width: 100%;

 }
 .clouds{
   position: relative;
   color:black;
   height: 72vh;
   width: 100%;
   border-radius: 25px;
   margin:auto 25px;
   background: rgba(255, 255, 255, 1);
   padding: 20px;
 }
 .clouds h6{
   position: absolute;
   bottom: 20px;
   left: 0;
   right: 0;
   margin: auto;
}

.foodGroup{
   position: relative;
   color:black;
   height: 89vh;
   display: flex;
   align-items: center;
   justify-content: center;
}

.veg{
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   background-image: url('../img/veg.svg');

}
.fruit{
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   background-image: url('../img/fruit.svg');

}
.dairy{
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   background-image: url('../img/dairy.svg');
}

.deleteBtn{
   float: right;
   border-radius: 2px;
   background-color: rgba(2,2,2,0.0);
   color: rgb(0, 0, 0);
   border: 0px solid white;
}
  
#addBtn{
   background-color: #004E64;
   border: none;
   box-shadow: none;
}
#addBtn:hover{
   background-color: rgb(53, 50, 50);
  
}

  
#chartBtn{
   border: none;
   box-shadow: none;
   background-color: #20a39e;
}
#chartBtn:hover{
   background-color: #004E64 ;
  
}
#activeChartBtn{
   border: none;
   box-shadow: none;
   background-color: #004E64;
}
.chartButtons{
   text-align: center;
}


.itemList{
   background-color: rgba(255, 255, 255, 1);
   text-align: left;
   max-height: inherit;
   height: 40%;
   width: 100%;
   margin-top: 60px;
   margin: 0 auto 10px;
   overflow:auto;
   list-style: none;
   color: white;
   padding:10px;
   color: black;
   
 }
.itemList li{
   padding-bottom: 3px;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
   .foodGroups{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../img/dash.svg');
   }
   .veg{
      background: none;
   }
   .fruit{
      background: none;
   }
   .dairy{
      background: none;
   }
   .itemList{
      background-color: rgba(255, 255, 255, 1);
      text-align: left;
      max-height: inherit;
      height: 40%;
      width: 100%;
      margin-top: 60px;
      margin: 0 auto 10px;
      overflow:auto;
      list-style: none;
      color: white;
      padding:10px;
      color: black;
    }
   .itemList li{
      padding-bottom: 3px;
   }

   .foodGroups{   
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../img/dash.svg');
      background-size: cover;
   }
   .veg{
      background-image: none;
   }
   .fruit{
      background-image: none;
   }
   .dairy{
      background-image: none;
   }
 }

