


.parent{
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../img/sign.svg');
}

.headings{
    text-align: center;
    padding: 10px 0px;
}

.headings img{
        width:120px;
        height: 120px;
        border-radius: 50%;
}

.child{
    min-width: 50%;

}
button.link {
    background:none;
    border:none;
    margin: 0;
    padding: 0;
    color: #0d6efd;
    text-decoration: underline;
}
button.link:hover{
color: #0b5ed7;

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .child{
         min-width: 30%;
    }
}

